.section-cart .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
}

.section-cart .m-t-contact-form {
    margin-top: 60px;
}

.section-cart .itm-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    // commented by dasprakash on 19-10-2023 jira-1574
    // margin: 4px 2px 14px;
    // margin: 4px 15px 14px;
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // background-color: #fff;


    // added by dasprakash on 19-10-2023 jira-1574
    margin: 4px 15px 20px;
    /* padding: 0px; */
    height: 36px;
    padding: 2px;
    white-space: pre-wrap;
}

.section-cart .itm-heading .city-txt {
    font-size: 10px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}

.section-cart .modifier-item {
    border-bottom: none !important;
    margin-top: -14px !important;
    // padding: 6px 10px !important;
    font-style: italic;
    // commented by dasprakash on 28-10-2023 jira-1677
    // height: 45px;
    padding: 3px !important;

    // margin-left: 2px;
}

.lrc-item-box {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
    -moz-box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
    box-shadow: 1px 1px 6px 1px rgba(177, 170, 170, 0.59);
}

.section-cart .wrap-list-rc .lrc-item {
    //commented by dasprakash on 19-10-2023 jira-1645
    // border-bottom: 1px solid #ecececcc;
    //added by dasprakash on 19-10-2023 jira-1645
    border-bottom: 0px solid #ecececcc;
    padding: 7px 0px;
}


.section-cart .wrap-list-rc .itm-modifier {
    color: #6c757de3;
    margin-left: 10px;
    font-size: 10px;
}

.section-cart .lrc-item:nth-child(even) {
    background-color: #fff;
}

.section-cart .regular-item {
    background-color: #fff;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content {
    position: relative;
    width: 100%;
    padding-left: 4px;
    min-height: 40px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content .lrc-img {
    width: 115px;
    position: relative;
    top: 2px;
    left: 0;
    overflow: hidden;
    height: 90px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-content .lrc-desc .lrc-title {
    font-weight: 600;
    width: 50%;

    // commented by dasprakash on 28-10-2023 jira-1677
    // padding-top: 5px;
    // added by dasprakash on 28-10-2023 jira-1677
    padding-top: 3px;

    font-size: 11px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left {
    float: left;
    width: 50%;
}

.wrap-list-rc .lrc-item .lrc-button .lrcb-right {
    float: right;
    width: auto;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-right .itm-add {
    border: 1px solid #ccc;
    color: #4CAF50;
    cursor: pointer;
    display: inline-block;
    padding: 6px 14px 4px 14px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-right .modifier-notify {
    font-size: 9px;
    text-align: center !important;
    color: #2b2e2f;
    padding: 2px;
    display: flex;
}

.section-cart .modifier-price {
    width: 28px !important;
    text-align: right !important;
    margin-right: -2px !important;
    display: inline-table;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #6F7072 !important;
    font-size: 14px !important;

}

.section-cart .wrap-list-rc .lrc-item .lrc-price {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left .svg-inline--fa {
    font-size: 12px;
    padding-right: 0px;
    color: #464545;
}

.section-cart .wrap-list-rc .lrc-item .lrc-button .lrcb-left .price {
    font-size: 14px;
    font-family: monospace;
    color: #000000;
}

.section-cart .qty-detail {
    float: right;
    margin-top: 2px;
    text-align: center;
    //commented by dasprakash on 19-10-2023 jira-1645
    // border: 1px solid #ddd;
    // margin-right: 26px;
    // padding: 2px;
    // width: 24%;
    // border: 1px solid rgba(0, 0, 0, 0.30);
    // box-shadow: 4px 2px 17px 0px rgba(0, 0, 0, 0.07);

    //added by dasprakash on 19-10-2023 jira-1645
    margin-right: 30px;
    padding-top: 2px;
    width: 71px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.84);
}

.section-cart .qty-detail .minus {
    float: left;
    //commented by dasprakash on 19-10-2023 jira-1645
    // color: #7b7b7b;
    // margin-left: 5px;
    // font-size: 12px;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #18A437B2;
    font-size: 12px;
    font-weight: 400;
    user-select: none !important;
}

.section-cart .qty-detail .plus {
    float: right;
    //commented by dasprakash on 19-10-2023 jira-1645
    // color: #000000;
    // margin-right: 5px;
    // font-size: 12px;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #18A437B2;
    font-size: 12px;
    font-weight: 400;
    user-select: none !important;

}

.section-cart .qty-detail .qty {
    //commented by dasprakash on 19-10-2023 jira-1645
    // color: #000;
    // font-size: 12px;
    // font-weight: 400;

    //added by dasprakash on 19-10-2023 jira-1645
    color: #18A437B2;
    font-size: 15px;
    font-weight: 600;
}

.section-cart .price-detail {
    float: right;
    margin-top: 4px;
    // margin-right: 12px;
    text-align: right;
    margin-right: 15px;
    width: 13%;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.section-cart .itm-delete {
    float: right;
    margin-top: 4px;
    cursor: pointer;
}

.section-cart .bill-details {
    background-color: #fff;
    // position: relative;
    // width: 100%;
    // padding-left: 8px;
    // margin-top: 12px;
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);

    margin: 15px;
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 100px;
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.07);
}

.section-cart .bill-details .content {
    min-height: 26px;
    //commented by dasprakash on 19-10-2023 jira-1645
    // font-size: 12px;

    font-size: 15px;
    font-weight: 500;
    color: #6D6E70;



}

.section-cart .bill-details .heading {
    //commented by dasprakash on 19-10-2023 jira-1645
    // font-size: 13px;
    // font-weight: 600;
    padding-bottom: 3px;
    //added by dasprakash on 19-10-2023 jira-1645
    color: rgba(255, 0, 0, 0.73);
    font-size: 15px;
    font-weight: 500;
}

.section-cart .bill-details .content .price {
    float: right;
    // commented by dasprakash on 21-10-2023 jira-1644
    // margin-right: 10px;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #000;


}

.section-cart .bill-details .tax {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.section-cart .bill-details .total {
    font-size: 14px;
    font-weight: 600;
    //commented by dasprakash on 19-10-2023 jira-1645
    // margin-bottom: 44px;
}

.section-cart .spec-info {
    background-color: #fff;
    position: relative;
    margin: 4px 15px;
    margin-top: 12px;
    // added by dasprakash on 26-10-2023 jira-1677
    display: flex;
    align-items: center;
    border-radius: 10px;
    //commented by dasprakash on 19-10-2023 jira-1645
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // width: 100%;
    // -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);


}

.section-cart .spec-info .instc {
    width: 100%;
    height: 24px;
    border: 1px solid #ddd;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.section-cart .pay-div {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #078bd1;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    // padding: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    height: 61px; ////Modified by vijay on 04-11-2023
    // padding :18px;  // Added by vijay on 11-11-2023
    cursor: pointer;
    max-width: 600px;
    left: inherit;
    border-top: 1px solid #078bd1;
    border-bottom: 1px solid #078bd1;
    background-color: #18A437;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    // color: #000;
    border-radius: 11px 11px 0px 0px;
}

// added by dasprakash on 21-10-2023 jira-1644
.section-cart .error-message {
    position: sticky; //Addded by vijay on 30-12-2023 Jira id -2075
    bottom: 35px; //Addded by vijay on 30-12-2023 Jira id -2075
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 110px;
    left: inherit;
    color: red;
    background: #f3f2f0; //Addded by vijay on 30-12-2023 Jira id -2075
}

.section-cart .pay-div .c-left {
    width: 50%;
    float: left;
    //commented by dasprakash on 19-10-2023 jira-1645
    // text-align: left;
    // padding-left: 4px;

    //added by dasprakash on 19-10-2023 jira-1645
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
    padding: 18px; //Modified by vijay on 04-11-2023
    border-radius: 10px 0px 0px 0px;

}


.section-cart .pay-div .c-right {
    width: 50%;
    float: left;
    //commented by dasprakash on 19-10-2023 jira-1645
    // text-align: right;
    text-align: center;
    padding-right: 4px;
    //added by dasprakash on 19-10-2023 jira-1645
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    background-color: #18A437;
    padding: 16px; //Modified by vijay on 04-11-2023
    border-radius: 0px 11px 0px 0px;
}

.section-cart .rupee {
    height: 21px;
    margin-right: 10px;
    margin-top: -3px;
}




.section-cart input[type="text"]::placeholder {

    /* Firefox, Chrome, Opera */
    text-align: left;
    color: #adadade3;
}

.section-cart input[type="text"]:-ms-input-placeholder {

    /* Internet Explorer 10-11 */
    text-align: left;
    color: #adadade3;
}

.section-cart input[type="text"]::-ms-input-placeholder {

    /* Microsoft Edge */
    text-align: left;
    color: #adadade3;
}

.section-cart .food-type-img {
    width: 12px;
    margin-right: 4px;
    margin-top: -3px;
}

.section-cart .spcecial-instc {
    padding: 2px 5px;
    margin-top: -13px; // Added by vijay
    //commented by dasprakash on 19-10-2023 jira-1645
    // margin-left: 12px;
    // text-decoration: underline;
    // color: #078bd1;
    // font-size: 11px;

    //added by dasprakash on 19-10-2023 jira-1645
    color: rgba(24, 164, 55, 0.70);
    font-size: 14px;
    font-weight: 400;

}

.special-insc {
    text-align: center;
    // width: 500px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #000;
    margin: 12px;
    display: inline-block;
}

.special-insc>button {
    width: 90px;
    padding: 10px;
    border: 1px solid #d1d5d7;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #000;
    font-size: 12px;
}

.special-insc .btn-left {
    float: left !important;
    // added by dasprakash on 26-10-2023 jira-1677
    font-weight: 500;
    font-size: 16px !important;
    border-radius: 11px;
}

.special-insc .btn-right {
    float: right !important;
    // added by dasprakash on 26-10-2023 jira-1677
    background-color: rgba(24, 164, 55, 0.70);
    font-size: 16px !important;
    border-radius: 11px;
    color: white;
    font-weight: 500;
}

.special-insc>textarea {
    width: 100%;
    min-height: 56px;
    resize: none;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 0.5rem;
    color: #666;
    box-shadow: inset 0 0 0.25rem #ddd;

    &:focus {
        outline: none;
        border: 1px solid darken(#ddd, 5%);
        box-shadow: inset 0 0 0.5rem darken(#ddd, 5%);
    }

    &[placeholder] {
        font-style: italic;
        font-size: 0.875rem;
    }
}


.special-insc .itm-name {
    text-transform: capitalize;
    color: #078bd1;
    font-size: 14px;
}

.special-insc-main {
    width: 100%;
    margin-top: 2px;
    // commented by dasprakash on 26-10-2023 jira-1645
    // margin-bottom: 8px;
    // display: inline-block;
}

.special-insc-desc {
    //commented by dasprakash on 20-10-2023 jira-1645
    // padding: 2px;
    // margin-left: 12px;
    // font-size: 10px;
    // border: 1px solid #d12f8b;
    // font-weight: 500;
    // color: #078bd1;
    // width: 186px;
    // background-color: #f1f3f6;
    // position: absolute;
    // margin-left: 4px;

    margin-top: -13px; //25px   // Modified by vijay
    font-size: 14px;
    font-weight: 400;
    width: 98%;
    border-radius: 2px;
    //added by dasprakash on 20-10-2023 jira-1645
    display: flex;
    // justify-content: space-between;
    color: #000;

}

#close-special-insc {
    line-height: 6px;
    width: 14px;
    font-size: 8pt;
    font-family: tahoma;
    margin-top: 1px;
    margin-right: 2px;
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 3px;
    color: #ff0a0a;
    border: 1px solid #d12f8b;
}

.section-cart .delivery {
    //commented by dasprakash on 19-10-2023 jira-1549
    // border-top: 1px solid #ddd;
    // border-bottom: 1px solid #ddd;


    // padding-top: 10px;
    // padding-bottom: 10px;
    // padding-left: 8px;
    font-size: 13px;
    // padding: 1px 10px;
    background-color: rgb(255, 255, 255); // Added by vijay on 10-11-2023
    border-radius: 10px;
    margin: 15px;
    padding: 8px;

}

.section-cart .delivery .addr-heading {
    font-weight: 600;
}

.section-cart .delivery .addr-type {
    font-size: 13px;
    color: #18A437;
}

.section-cart .delivery .address {
    font-size: 13px; // Added by vijay on 16-11-2023
    margin-top: 8px;
}

.section-cart .delivery .change-btn {
    // border-radius: 0px;
    font-size: 14px; //Modified by vijay on 09-11-2023
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #18A437 !important;
    color: #000;
    font-weight: 600;
    background: #DCF1E1;
}

.section-cart .delivery .choose-btn {
    border-radius: 0px;
    font-size: 11px;
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    margin-right: 6px;
    margin-top: -20px;
}

.section-cart .delivery .not-login-msg {

    // commented by dasprakash on 19-10-2023 jira-1549
    // font-size: 11px;
    // color: #078bd1;
    // font-weight: 600;
    text-align: center;

    // added by dasprakash on 19-10-2023 jira-1549
    color: rgba(24, 164, 55, 0.65);
    font-size: 15px;
    font-weight: 500;
    background-color: #FFF;
    border-radius: 10px;
    padding: 5px;
}

.section-cart .delivery .not-login-msg .login {
    //commented by dasprakash on 19-10-2023 jira-1549
    // font-size: 12px;
    // color: #d12f8b;
    // font-weight: 600;

    //added by dasprakash on 19-10-2023 jira-1549
    color: #13832C;
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
}

// added by dasprakash on 21-10-2023 jira-1644
@media (min-width: 430px) {
    .section-cart .price-detail {
        margin-right: 0px;
    }
}

@media(min-width: 430px) {

    .section-cart .spcecial-instc {
        padding: 10px 5px;
        margin-top: -17px;
        color: rgba(24, 164, 55, 0.70);
        font-size: 14px;
        font-weight: 400;

    }

}

.section-cart .content-message {
    user-select: auto;
    margin-bottom: 62px;
    padding: 5px 6px;
    /* text-align: center; */
    /* margin: 8px 8px 8px 8px; */
    color: #212529;
    background-color: #dbf9df;
    font-size: 11px;
    border-radius: 7px;
    border-left: 6px solid #18A437 !important;
    max-height: 45px;
    text-align: left !important;
}