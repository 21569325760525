#content {
	width: 100%;
	min-height: calc(100vh - 56px);
	transition: all 0.3s;
	/* position: absolute; */
	top: 56px;
	right: 0;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 52px;
	background-color: #fff;
   }

   .bg-header {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: inherit;
    height: 56px;
    width: 100%;
    background-color: #fff;
	border-bottom: 0px solid #ddd;
	max-width: 600px;
   }

   .navbar .logo, .navbar #sidebarleftbutton.btn, .navbar #sidebarrightbutton.btn
   {
	color: #d12f8b;

   }
/*************** BOX-MENU ****************/
.home-param-txt
{
	margin-bottom: 4px;
    font-size: 13px;
    font-weight: 400;
}
.home-param-value-txt
{
	color: #333a40;
    font-weight: 700;
    font-size: 12px;
}
.home-menu.box-menu > .row{
	margin-left:6px;
	margin-right:6px;
}

.home-menu.box-menu ul{
	overflow:hidden;
	padding:0px 6px;
	background-color: #fff;
}

.home-menu.box-menu ul li{
	width:33.33333333%;
	float:left;
	height:100%;
    padding:0px 10px;
    
}

.home-menu.box-menu ul li .dish-list-link{
	color:#333;
	text-align: center;
	padding-bottom: 100%;
	height: 0px;
	width: 100%;
	position: relative;
	margin: 10px 0px 10px;
	display:block;
	// border:1px solid #e6e6e6;
	background:white;
}

.home-menu.box-menu ul li:hover > .dish-list-link{
	box-shadow:0px 4px 12px rgba(0,0,0,0.2);
}

.home-menu.box-menu ul li .dish-list{
	width: 100%;
	height: 100%;
	display: table;
	position: absolute;
	padding:0px;
}

.home-menu.box-menu ul li .dish-list .dish-list-tbl-cell{
	display: table-cell;
	text-align: center;
	vertical-align: middle;
    padding:10px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
	box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
	//border: 1px solid #e6e6e6;    
}

.home-menu.box-menu ul li .dish-list img{
	padding:0px;
	width:50%;
}

.home-menu.box-menu ul li .dish-list .dish-list-text h4{
	font-size:12px;
	margin-top:5px;
	word-wrap:break-word;
}

.navbar .home-logo
{
	font-size: 16px;
    color: #212529;
    font-weight: 400;
} 

.home-menu .order-now
{
	border: 1px solid #d12f8b;
	padding: 9px 22px;
    font-size: 13px;
    font-weight: 400;
	border-radius: 0.25rem;
    text-align: center;
    width: 50%;
    margin: 12px auto;
    color: #212529;
    cursor: pointer;
}

.custom-section-subscribe
{
	margin: 2px 2px 2px 10px;
}
.side-menu-li
{
	border-bottom: 1px solid #ddd;
	padding: 4px;
}
#sidebarleft
{
	background-color: #fff;
}
#sidebarleft a, #sidebarleft a:hover
{
	color: #212529;
}
#sidebarleft ul .svg-inline--fa
{
	color: #d12f8b;
}
#sidebarleft .sidebar-header
{
	border-bottom: 1px solid #078bd163;
    background-color: #fff;
}
.sdprofile .sdp-right .sd-name
{
	color: #212529;
	margin-top: 8px;
}
.sdprofile .sdp-left img
{
	 width: 100%; 
    height: 100%; 
}
.sdprofile
{
	padding-left: 73px;
}
 .custom-heading-top {   
    margin-bottom: -30px !important;
    
}

.bottom-clear
{
	margin-bottom: 16px;
	display: flex;
}

.home-content
{
	padding: 9px 22px;    
    font-weight: 400;    
    text-align: center;    
    margin: 22px auto;
    color: #212529;    
    background-color: #f2f3f5;
    border-left: 3px solid #d12f8b;
}

@media(min-width:992px){
	
	.home-menu.box-menu ul li{
		width:16.66666667%;
	}
}



/************** BOX-MENU-2 ***************/