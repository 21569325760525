.access-model {
    background: #FFF;
}

.outlet-details-v2 {
    border-radius: 11px 0px 80.5px 11px;
    border: 1px solid #CEEADC;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.10);
    height: 161px;
    display: flex;
    margin: 10px;
    max-width: 390px;


    &-section1 {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &-section2 {
        width: 50%;
        border-radius: 80.5px 0px 80.5px 11px;
        border-top: 1px solid #D3EDDE;
        border-right: 1px solid #D3EDDE;
        border-bottom: 1px solid #D3EDDE;
        border-left: 2px solid #D3EDDE;
        background: linear-gradient(133deg, rgba(180, 255, 197, 0.25) 3.91%, #FFF 80.67%);
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;

        span {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
        }

        svg {
            margin: 4px;
        }
        .foodtype-icon-v2 {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.servicetype-v2 {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 125px;
        height: 90.241px;
    }

    &-services {
        height: 196.598px;
        width: 243px;
        border-radius: 80.5px 0px 80.5px 11px;
        border-top: 1px solid #D3EDDE;
        border-right: 1px solid #D3EDDE;
        border-bottom: 1px solid #D3EDDE;
        border-left: 2px solid #D3EDDE;
        background: linear-gradient(133deg, rgba(180, 255, 197, 0.25) 3.91%, #FFF 80.67%);
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.back-to-outlet {
    max-width: 350px;
    width: 90%;
    height: 74px;
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;

    svg {
        margin-left: 10px;

    }

    span {
        width: 90%;
        display: flex;
        justify-content: center;
        color: #000;
        font-size: 16px;
        font-weight: 500;
    }
}