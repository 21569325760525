
.section-processfailed .heading-page
{
    z-index: 10;
    position: fixed !important;
    top: 0px;  
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;    
    padding: 8px 15px 14px 10px;  
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color:  #212529;
    border-bottom: 1px solid #078bd1;  
}
.section-processfailed .m-t-contact-form
{
    margin-top: 100px;
}


.section-processfailed .payment-txt{
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-processfailed .message-details{
    background-color: #fff;    
    width: 86%;
    border-radius: 14px;
    margin: 0 auto;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
	box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}
.section-processfailed .message-details .content
{   
    font-size: 12px;
    // border-bottom: 1px solid #ddd;   
    padding: 4px;
    cursor: pointer;
    margin-top: 104px;
}

.section-processfailed .message-details .msg
{    
    font-size: 24px;
    font-weight: 600;   
    text-align: center;
}
.section-processfailed .message-details .msg-order-id
{   
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 12px;
    font-weight: 500;
}

.section-processfailed .message-details .btn
{   
    padding: 6px 89px;
    display: block;
    margin: 0 auto;
    background-color: #078bd1;
    margin-top: 80px;
    border-radius: 0;
    margin-bottom: 10px;
    border: 1px solid #078bd1;
    /* padding: 9px 22px; */
    color:  #ffffff;
}

.section-processfailed .message-details .btn-orders
{   
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 500;
}

.logo-v2-failed {     //Added by vijay on 
    align-items: center;
    background: #CBE9DA;
    border-bottom-left-radius: 60%;
    border-bottom-right-radius: 60%;
    display: flex;
    height: 170px;
    justify-content: center;
    margin-top: -52px;
  } //End

  .section-orderfailed .message-details{
    background-color: #fff;    
    width: 86%;
    border-radius: 14px;
    margin: 0 auto;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
	box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-orderfailed .message-details .msg
{    
    font-size: 20px;   
    font-weight: 600;   
    text-align: center;
     color: #FF0000; 
}
.section-orderfailed .message-details .content
{   
    font-size: 12px;   
    padding: 4px;
    cursor: pointer;
    margin-top: 104px;
}
