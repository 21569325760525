.loading_img {
  margin-left: -25px !important;
    margin-top: 10px !important;
}
.svg{
  width: 10%;
  height: 10%;
  // margin-top: 20%
}

.qrcode_bg{
  background: #fff;
  min-height: calc(130vh - 156px) !important;
}
.qrcode-txt-center
{
  text-align: center;     
    margin-top: 117px;   
}

.qrcode-img-center
{
  text-align: center;
    position: fixed;
    top: 56%;
    left: 50%;
    margin-top: -50px;
    margin-left: -80px;
}

.qrcode-oops-txt
{
  font-size: 26px;
    font-weight: 700;
}
.qrcode--txt-not-found
{                                        
  // font-size: 24px;
  //   font-weight: 600;   // Modified by vijay 
  //   color: #212529;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #18A437;
}


.qrcode-msg-txt
{
  font-size: 12px;
    font-weight: 700;
    color: #82878c;
   
}


.logo-v2-logout {     //Added by vijay on 25-10-2023 Jira id -1687 -start
  align-items: center;
  background: #CBE9DA;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;
  display: flex;
  height: 170px;
  justify-content: center;
  
}
.content-wrap{
  max-width: 600px;

}