.section-modifier .modal-content
{
    border-radius: 0;
    position: absolute; 
    // bottom: 0px; 
    bottom: 40px;
}
.section-modifier .modal-title
{
    font-size: 13px;
    font-weight: 400;
}
.section-modifier .modal-header
{
    padding: 8px 8px;
    // background-color: #ebf9ef;
    border-bottom: 1px solid #078bd1;   

}
.section-modifier .modal-body 
{
    padding: 8px;
    max-height: 370px;
    overflow-x: auto;
}
.section-modifier .food-type-img
{
    margin-top: -3px;
}
.section-modifier .modal-body .food-type-img 
{
    margin-left: 6px;
}
.section-modifier .modal-body .modifier-group-li
{
    line-height: 12px;
    list-style: none;
}
.section-modifier .modal-body .modifier-group-li .group-name
{    
    font-size: 12px;
    font-weight: 700;
}
.section-modifier .modal-body .modifier-group-li .minimum-selection
{
    font-size: 16px;
    vertical-align: middle;
    margin-left: -12px;
    position: relative;
    margin-right: 5px;
    font-weight: 400;
}

.section-modifier .modal-body .modifier-li
{
    line-height: 28px;
    margin-top: 4px;
    list-style: none;
    margin-left: -60px;
}
.section-modifier .modal-body .rate-inr
{
    font-size: 11px;
    color: #585252;
}
.section-modifier .modal-footer
{
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
    // box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
    padding: 2px;
}

.section-modifier .modal-footer .cart
{    
    //position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    font-size: 13px;
    color: #212529;  
    padding: 10px;
    font-weight: 400;
    height: 40px;
    cursor: pointer;
    z-index: 10;
    left: inherit;
    max-width: 600px;
    border-top: 1px solid #078bd1;
    border-bottom: 1px solid #078bd1;   
}

.section-modifier .cart .c-left
{
   float: left;   
}
.section-modifier .cart .c-right
{
    float: right;   
}

.section-modifier .close {
    float: right;
    font-size: 18px;    
    line-height: 1;
    color: #212529;
    text-shadow: 0 1px 0 #fff;
    /* opacity: 0.5; */
}