.section-mybills .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    /* font-size: 13px;  Comment by prem  */
    /* color: #212529; Comment by prem  */
    /* Added by prem */
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.section-mybills .m-t-contact-form {
    margin-top: 60px;
}


.section-mybills .mybills-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-mybills .order-details {
    background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 6px;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-mybills .order-details .heading {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-mybills .order-details .content {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    height: 80px;
    padding: 14px;
    cursor: pointer;

}

.section-mybills .order-details .content .orderno {

    font-size: 14px;
    font-weight: 500;


}

.section-mybills .order-details .content .btn-detail {
    float: right;
    border: 1px solid #ec740f;
    padding: 5px 19px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 14px;

}

.section-mybills .order-details .content .date {
    color: #878788;
}

.section-mybills .order-details .content .status-1 {
    color: #1171d8;
    font-weight: 700;

}

.section-mybills .order-details .content .status-2 {
    color: #058c24;
    font-weight: 700;

}

.section-mybills .order-details .content .status-3 {
    color: #ff0202;
    font-weight: 700;

}

.section-mybills .refresh-icon {
    float: right;
    font-size: 16px;
    color: #000000;
}

.section-mybills .gallery-section {
    padding: 6px 9px;
    margin-bottom: 15px;
}

.section-mybills .gallery-section .gall-col {
    width: 100%;
    float: left;
}

.section-mybills .gallery-section .filter-button {
    color: #212529;
    background: transparent;
    border: 0px solid #ffffff;
    padding: 10px 10px;
    font-weight: 400;
    margin-bottom: 5px;
    border: 1px solid #cecece;
    width: 48%;
    margin-left: 2px;
}

.section-mybills .gallery-section .row-gallery {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -20px;
}

.section-mybills .accordion {
    background-color: #fff;
}

.section-mybills .accordion-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    margin: 4px 12px 4px;
    padding-left: 2px;
    border-bottom: 1px solid #ddd;
}

.section-mybills .accordion-heading .itm-cnt {
    color: #878788;
    font-size: 10px;
}

.section-mybills .accordion-heading .accordion-toggle {
    display: block;
    padding: 8px 4px;
}

.section-mybills .gallery-section .order-details .btn-detail {
    float: right;
    border: 1px solid #078bd1;
    padding: 5px 19px;
    font-size: 11px;
    font-weight: 500;
    border-radius: 0px;
}

.section-mybills .gallery-section .order-details .status-3 {
    color: #ff0202;
    font-weight: 600;
}

.section-mybills .gallery-section .order-details .status-1 {
    color: #1171d8;
    font-weight: 600;
}

.section-mybills .gallery-section .order-details .status-0 {
    color: #27ab45;
    font-weight: 600;
}

.section-mybills .gallery-section .filter-button.active {
    color: #212529 !important;
    border: 1px solid #d12f8b;
}

.section-mybills .gallery-section .order-details table {
    border-bottom: 1.2px dotted #5f5b5b;
}

.section-mybills .gallery-section .order-details .table td,
.table th {

    border-top: 0px solid #dee2e6;
}

.section-mybills .gallery-section .order-details .btn-bill-detail {
    float: right;
    border-bottom: 1px solid #d12f8b;
    padding: 4px 4px;
    font-size: 10px;
    font-weight: 500;
    /* border-radius: 14px; */
    margin-right: 13px;
    color: #212529;
    ;
}

.section-mybills .no-oders {
    text-align: center;
    font-size: 11px;
    color: #ff0018ab;
    font-weight: 500;
}

.section-mybills .thead-txt {
    font-size: 12px;
    color: #212529;
    width: 100%;
}

.section-mybills .tbody-txt {
    font-size: 12px;
}

.section-mybills .modifier-group-txt {
    font-size: 11px;
    padding-left: 20px;
}

.section-mybills .modifier-item-txt {
    font-size: 10px;
    padding-left: 24px;
}

.section-mybills table {
    text-transform: capitalize;
}

// Added by prem 
.table td,
.table th {
    border-top: none !important;
}

.section-mybills .pay-now {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #078bd1;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    padding: 18px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    height: 61px;
    cursor: pointer;
    max-width: 600px;
    left: inherit;
    border-top: 1px solid #078bd1;
    border-bottom: 1px solid #078bd1;
    background-color: #18A437;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    color: #fff;
    border-radius: 11px 11px 0px 0px;
}

.section-mybills .order-amount {
    font-weight: 600;
    font-size: 12px;
    margin: 54px 2px 3px 14px;
    color: #3f4042c7;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 12px;
    padding: 11px;
}

.section-mybills .order-header {
    background-color: white;
    border-radius: 10px;
    margin: 15px;
    padding: 20px;
    padding-top: 10px;
}

.section-mybills .table-bills {
    background-color: white;
    border-radius: 10px;
    margin: 15px;
    padding: 20px;
    padding-top: 10px;
}

.section-mybills .bill-details {
    // background-color: #fff;
    position: relative;
    width: 100%;
    padding-left: 8px;
    margin-top: 12px;
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-mybills .bill-footer {
    background-color: white;
    border-radius: 10px;
    margin: 11px 12px 1px 8px;
    padding: 15px;
}

.section-mybills .bill-details .content {
    min-height: 26px;
    font-size: 15px;
    font-weight: 500;
    color: #6D6E70;
}

.section-mybills .bill-details .heading {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 3px;
    color: rgba(255, 0, 0, 0.73)
}

.section-mybills .bill-details .content .price {
    float: right;
    margin-right: 10px;
    color: #000;
}

.section-mybills .bill-details .tax {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.section-mybills .bill-details .total {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 44px;
}

.section-mybills .pay-to-img {
    width: 18%;
    height: auto;
    border: 1px solid #ddd;
    padding: 6px;
    margin-left: 12px;
    float: left;
    margin-right: 6px;
}

.section-mybills .rupee {
    height: 21px;
    margin-right: 10px;
    margin-top: -3px;
}

.section-mybills .orgname {
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    width: 130px;
    display: inline-block;
    line-height: 15px;
    padding-top: 35px;
}

.section-mybills .outlet-name {
    font-size: 13px;
    font-weight: 500;
    // padding-left: 6px;
    margin-top: -2px;
    // margin-left: 88px;
    display: block;
    color: #8e9294;
    margin-bottom: 18px;

}

.section-mybills .total-amount {
    float: right;
    // margin-right: 19px;
    margin-top: -44px;
}

.section-mybills .total-amount-txt {
    font-size: 15px;
    font-weight: 500;
    margin-right: 4px;
    color: #6D6E70;
}

.section-mybills .total-amount-val {
    color: #000000;
    margin-right: 12.5px;
    font-weight: 600;
    border: 1px solid #ddd;
    padding: 4px;
}

// .section-mybills tbody {
//     display: block;
//     height: 216px;
//     overflow: auto;
// }
// .section-mybills thead, tbody tr {
//     display:table;
//     width:100%;
//     table-layout:fixed;/* even columns width , fix width of table too*/
// }
// .section-mybills thead {
//     width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
// }

.section-mybills .item-name-col {
    width: 48%;
}

.section-mybills .no-order-img {
    /* Absolute centering */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    /* Size limit */
    max-width: 100%;
    max-height: 100%;

    /* Other required settings */
    margin: auto;
    overflow: auto;
}

.section-mybills .no-order-txt {
    font-size: 13px;
    font-weight: 500;
    position: fixed;
    top: 60%;
    text-align: center;
    display: block;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

.section-mybills .no-order-btn-div {
    font-size: 13px;
    font-weight: 500;
    position: fixed;
    top: 70%;
    text-align: center;
    display: block;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}


.section-mybills .no-order-btn {
    padding: 6px 36px;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 42px;
    border-radius: 0;
    margin-bottom: 10px;
    border: 1px solid #d1d5d7;
    /* padding: 9px 22px; */
    color: #212529;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    color: #000;
}

.section-mybills .error-msg {
    position: sticky;
    bottom: 25px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 52px;
    left: inherit;
    color: red;
    background: #f3f2f0;
}


@media screen and (min-width: 350px) and (max-width: 520px) {
    .section-mybills .order-header {
        background-color: white;
        border-radius: 14px;
        margin: 12px;
        padding: 5px 0px 9px 1px;
        padding-top: 13px;
    }

    .section-mybills .orgname {
        font-size: 16px;
        font-weight: 600;
        vertical-align: middle;
        width: 130px;
        display: inline-block;
        line-height: 15px;
        padding-top: 6px;
    }

    .section-mybills .outlet-name {
        font-size: 13px;
        font-weight: 500;
        margin-top: -2px;
        display: block;
        color: #8e9294;
        margin-bottom: 25px;
    }

    .section-mybills .total-amount {
        float: right;
        margin-top: -26px;
    }

    .table-th-tr {
        font-size: 13px !important;
        font-weight: 500 !important;
        color: rgb(0, 0, 0) !important;
    }

    .table-tbody-tr-ma {
        font-size: 13px !important;
        font-weight: 500 !important;
        color: rgb(0, 0, 0) !important;
        padding-right: 2px !important;
    }

    .table-tbody-tr-mo {
        font-size: 12px !important;
        font-weight: 500 !important;
        color: rgb(0, 0, 0) !important;
        padding-right: 2px !important;
    }

    .section-mybills .table-bills {
        background-color: white !important;
        border-radius: 10px !important;
        margin: 12px !important;
        padding: 5px !important;
        padding-top: 10px !important;
    }

    .section-mybills .bill-footer {
        background-color: white !important;
        border-radius: 10px !important;
        margin: 11px 13px 1px 5px !important;
        padding: 15px !important;
    }

    .table-tbody-td-span {
        font-family: 'Inter' !important;
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    .section-mybills .total-amount-txt {
        font-size: 15px;
        font-weight: 600;
        margin-right: 53px;
        color: #18191a;
    }
}