// $myFont: Helvetica, sans-serif;
// $myColor: red;
// $myFontSize: 18px;
// $myWidth: 680px;
// $bgColor: #59324c;
// $fontColor: #fff;

.top{
    top:0 !important;
}


.em-itm-bg-pattern
{
//   border: 2px solid black;
//   padding: 25px;
  background: url('bg1.jpg') !important;
  background-repeat: no-repeat;
  background-size: auto;
  font-family: Raleway, sans-serif !important;
  border: none !important;
  padding-bottom: 14px !important;
}

.ribbon-heading {
    width: 48%;
    height: 56px;
    position: relative;
    float: left;
    margin-bottom: 30px;
    // background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
    background-size: cover;
    text-transform: uppercase;
    color: white;
  }
  .ribbon-heading:nth-child(even) {
    margin-right: 4%;
  }
  @media (max-width: 500px) {
    .ribbon-heading {
      width: 100%;
    }
    .ribbon-heading:nth-child(even) {
      margin-right: 0%;
    }
  }

.ribbon3 {
    width: 250px;
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
    position: absolute;
    left: -8px;
    top: 20px;
    background: #59324C;
    word-break: inherit;
    font-size: 17px;
  }
  .ribbon3:before, .ribbon3:after {
    content: "";
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    top: -8.5px;
    left: 0.1px;
    border-bottom: 9px solid black;
    border-bottom: 9px solid #59324c;
    border-left: 9px solid transparent;
  }
  .ribbon3:after {
    height: 0;
    width: 0;
    right: -14.5px;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 15px solid #59324C;
  }

  .em-itm-heading-txt
  {
    background-color: #59324c !important;
    color: #fff !important;
    height: 60px !important;
    font-size: 18px !important;
    padding: 10px 14px 8px 40px;
    border-bottom: 1px solid #fff !important;
  }

  .em-itm-heading-city-txt
  {
    color: #f9bd09 !important;
    font-size: 12px !important;
  }

  .em-itm-txt
  {    
    width: 100% !important;
    font-size: 16px ;
    font-weight: 600 !important;
    color: #59324c ;  
    border-bottom: 1px solid;
    line-height: 36px;
}   
  

  .em-itm-title
  {
    width: 80% !important;
  }

  .em-itm-desc
  {
    font-size: 12px ;  
    color: #59324c ;
    font-weight: 600 !important;
  }

  .em-itm-price-txt
  {
    font-size: 16px;
    font-weight: 600 !important;
    color: #59324c;     
    float: right;
    text-align: right;
    width: 20% !important;
  }

  .em-itm-food-type-img
  {
    width: 12px;
    margin-right: 4px;
    margin-top: -5px;    
  }

  .dmenu-section-items .accordion-heading .em-itm-subheading-txt
  {
    color: #59324c !important;
    font-size: 15px !important;
  }



.dmenu-section-items .heading-page
{
    z-index: 10;
    position: fixed !important;
    top: 0px;  
   // left: 0px;
   left: inherit;
    // height: 50px;
    height: 110px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #078bd1;  
    max-width: 600px;
    font-size: 13px;
    color:  #212529;
}
.dmenu-section-items .city-txt{
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}
.dmenu-section-items .m-t-contact-form
{
    margin-top: 48px;
}
.dmenu-section-items .search {
    float: right;
    padding: 2px 4px;
    border: .2px solid #f7f3f3;
    background-color: #fff;
    margin: 2px 7px -10px -7px;
}


.dmenu-section-items {
    position: relative;
    background-size: cover !important;
    margin-top: 104px;
}
.dmenu-section-items .itm-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    margin: 4px 12px 4px;
    display: flex;
}

.dmenu-section-items .recommended-item{
    background-color: #fff;    
   // float: left;
}


.dmenu-section-items .wrap-list-rc .lrc-rec-item {
    border-bottom: none;
    padding: 10px 10px;
    width: 50%;
    //float: left;
    display: inline-block;
}
.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-content {
    position: relative;
    width: 100%;
    padding-left: 4px;   
//    min-height: 70px;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-content .lrc-img {
   // width: 105px;
    position: relative;
    top: 2px;
    left: 0;
    overflow: hidden;
    //height: 105px;
}
.wrap-list-rc .lrc-rec-item .lrc-content .lrc-img img {
    // height: auto;
    // height: 94px;
    // width: 105px !important;
    height: 130px;

    // -webkit-border-radius: 5px;
    // -moz-border-radius: 5px;
    // border-radius: 5px;
}
.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button {
    color: #212529;
    max-width: 100%;
    margin: 10px auto 0px;
}
.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-left {
    float: left;
    width: 50%;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-right .itm-add {       
    border: 1px solid #ccc;
    color: #4CAF50;
    cursor: pointer;
    display: inline-block;
    padding: 6px 14px 4px 14px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
}
.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-right {
    float: left;
    width: 50%;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-right .modifier-notify {
    font-size: 9px;
    text-align: center !important;
    color: #2b2e2f;
    padding: 4px;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-price {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-left .svg-inline--fa {
    font-size: 12px;
    padding-right: 0px;
    color: #464545;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-button .lrcb-left .price {
    font-size: 12px;
    // font-family: monospace;
    color: #000000;
    padding-left: 16px;
}
.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-content .lrc-desc .lrc-title {
    font-weight: 500;
    font-size: 12px;
    margin-top: 4px;
    // font-family: sans-serif;
    text-transform: capitalize;
    min-height: 40px;
    float: left;
    min-width: 100%;
}

.dmenu-section-items .wrap-list-rc .lrc-rec-item .lrc-content .lrc-desc .lrc-text
{
    font-size: 10px;
    //padding-left: 2px;
    display: inline-block;
    //width: 169px;
    //white-space: nowrap;
    //overflow: hidden !important;
    text-overflow: ellipsis;  
    color: #6b6868;   

    text-transform: capitalize;

}
.dmenu-section-items .wrap-list-rc .lrc-item {
    border-bottom: 0;
    padding: 6px 10px;
}
.dmenu-section-items .lrc-item:nth-child(even) {
    background-color: transparent;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-content {
    position: relative;
    width: 100%;
    padding-left: 4px;
    //min-height: 70px;
    min-height: 0px;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-content .lrc-img {
    width: 115px;
    position: relative;
    top: 2px;
    left: 0;
    overflow: hidden;
    height: 90px;
}
.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}
.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button .lrcb-left {
    float: left;
    width: 50%;
}
.wrap-list-rc .lrc-item .lrc-button .lrcb-right {
    float: right;   
    width: auto;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button .lrcb-right .itm-add {       
    border: 1px solid #ccc;
    color: #4CAF50;
    cursor: pointer;
    display: inline-block;
    padding: 6px 14px 4px 14px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button .lrcb-right .modifier-notify {
    font-size: 9px;
    text-align: center !important;
    color: #2b2e2f;
    padding: 2px;
    display: flex;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-price {
    color: #7b7b7b;
    max-width: 100%;
    margin: 10px auto 0px;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button .lrcb-left .svg-inline--fa {
    font-size: 12px;
    padding-right: 0px;
    color: #464545;
}

.dmenu-section-items .wrap-list-rc .lrc-item .lrc-button .lrcb-left .price {
    font-size: 12px;
    // font-family: monospace;
    color: #000000;
    padding-left: 16px;
}
.wrap-list-rc .lrc-item .lrc-content .lrc-desc .lrc-title {
    font-weight: 500;
    width: 70%;
    font-size: 12px;
   // font-family: sans-serif;    
    text-transform: capitalize;
}



.dmenu-section-items .wrap-list-rc .lrc-item .lrc-content .lrc-desc .lrc-text
{
    font-size: 10px;
    color: #6b6868;   
    padding-left: 16px;
    text-transform: capitalize;
}

.dmenu-section-items .f-l
{
    float:left;
}

.dmenu-section-items .f-r
{
    float:right;
    margin-top: 18px;
}


/*Begin::Ribbon Price Tag*/
  .dmenu-section-items .ribbon {
      position: absolute;
      top: 4px;
      padding: 0px 1px 1px 2px;
      background: #f10b21;
      box-shadow: -1px 2px 3px rgba(0,0,0,.3);
      z-index: 8;
      color: #fff;
      font-weight: 600;
      border: 1px solid rgba(253, 253, 253, 0.53);
  }
  .dmenu-section-items .ribbon:before, .ribbon:after {
    content: "";
    position: absolute;
  }
  .dmenu-section-items .ribbon:before {
    width: 7px;
    height: 100%;
    top: 6px;
    left: -6.5px;
    padding: 0 0 7px;
    background: inherit;
    border-radius: 5px 0 0 5px;
  }
  .dmenu-section-items  .ribbon:after {
    width: 5px;
    height: 5px;
    bottom: -5px;
    left: -4.5px;
    background: #2e3f49;
    border-radius: 5px 0 0 5px;
   }

    .dmenu-dmenu-section-items .ribbon .txt
    {
        font-size: 10px;
        margin-top: -1px;
        margin-left: 2px;
        display: block;
        margin-right: 2px;
        margin-bottom: -2px;
    }
    
   

  /*End::ribbon Price Tag*/

 // .accordion-opened .accordion-toggle:after {
    //     display: inline-block;
    //     content: '';
    //     height: 10px;
    //     width: 10px;
    //     margin-right: 12px;
    //     border-bottom: 2px solid currentColor;
    //     border-right: 2px solid currentColor;
    //     transform: rotate(45deg);
    // }   
   
    // .accordion-toggle:after {
    //     font-family: 'FontAwesome';
    //     content: "\f078";
    //     display: inline-block;
    //     content: '';
    //     height: 10px;
    //     width: 10px;
    //     margin-right: 12px;
    //     border-bottom: 2px solid currentColor;
    //     border-right: 2px solid currentColor;
    //     transform: rotate(45deg);
    // }

    .accordion-toggle
    {
        color: #000;
    }


    .accordion-toggle > a .collapsed
    {
        color: var(--blue) !important ;
    }


#sidebarleft ul li.active > a, a[aria-expanded="true"] {
    color: #000;
}


.dmenu-section-items .accordion-heading {
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    margin: 4px 12px 4px;
    padding-left: 2px;
    border-bottom: 1px solid #e9e7e7;
}
.dmenu-section-items .accordion-heading .itm-cnt
{
    color: #6b6969;
    font-size: 10px;
    float: right;
}
.dmenu-section-items .accordion-heading .accordion-toggle {
    display: block;
    padding: 8px 4px;
    font-size: 12px;
}


.dmenu-section-items .qty-detail{
    float: right;    
    margin-top: 6px;
    border: 1px solid #ddd;
    padding: 4px;
    width: 20%;
    text-align: center;
}

.dmenu-section-items .qty-detail .minus{
    color: #7b7b7b;
    font-size: 12px;
    float: left;
}

.dmenu-section-items .qty-detail .plus{
    color: #d12f8b;
    font-size: 12px;
    float: right;
}
.dmenu-section-items .qty-detail .qty{
    color: #000;
    font-size: 12px;
    font-weight: 400; 
}

.dmenu-section-items .rec-qty-detail{
    float: right;
    margin-top: -7px;
    border: 1px solid #ddd;
    padding: 4px;
    width: 43%;
    text-align: center;
}

.dmenu-section-items  .qty-show{
    display: block;
}
.dmenu-section-items  .qty-hide{
    display: none;
}

.dmenu-section-items .rec-qty-detail .minus{
    color: #7b7b7b;
    font-size: 12px;
    float: left;
}

.dmenu-section-items .rec-qty-detail .plus{
    color: #d12f8b;
    font-size: 12px;
    float: right;
}
.dmenu-section-items .rec-qty-detail .qty{
    color: #000;
    font-size: 12px;
    font-weight: 400;
}

.dmenu-section-items .rec-qty-detail .add{
    color: #212529;
    font-size: 12px;
    font-weight: 500
}

.dmenu-section-items .clear-bottom
{
    background-color: #6c757d45;
    height: 4px;
    margin-top: 4px;
    
}


.animate-bottom {
    position: relative;
    animation: animatebottom 0.4s;
  }
  
  @keyframes animatebottom {
    from {
      bottom: -300px;
      opacity: 0;
    }
  
    to {
      bottom: 0;
      opacity: 1;
    }
  }
// Modal - End
// .accordion .card-header:after {
//     font-family: 'FontAwesome';  
//     content: "\f068";
//     float: right; 
// }
// .accordion .card-header.collapsed:after {
//     /* symbol for "collapsed" panels */
//     content: "\f067"; 
// }


.dmenu-section-items input[type="text"]::placeholder {  
      
    /* Firefox, Chrome, Opera */ 
    text-align: left; 
    color: #adadade3;
} 
.dmenu-section-items input[type="text"]:-ms-input-placeholder { 
      
    /* Internet Explorer 10-11 */ 
    text-align: left; 
    color: #adadade3;
} 
.dmenu-section-items input[type="text"]::-ms-input-placeholder {  
      
    /* Microsoft Edge */ 
    text-align: left; 
    color: #adadade3;
} 
.dmenu-section-items .search-wrapper
{
    width: 76%;
    margin-left: -34px;    
}
.dmenu-section-items .search-wrapper input#search
{
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 13px;
}

.dmenu-section-items .search-wrapper button.ssubmit
{
    color: #d3d3d3;
}

.dmenu-section-items .custom-control-label
{
    // color: #5fce78;
    font-size: 14px;
}


.dmenu-section-items .item-content
{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    font-size: 13px;
    color: #212529;  
    padding: 10px;
    font-weight: 500;
    height: auto;
    cursor: pointer;
    z-index: 10;
    left: inherit;
    max-width: 600px;
    //border-top: 1px solid #078bd1;
    //border-bottom: 1px solid #078bd1;  
    background-color: #f8f5e6;
    border-left: 3px solid #d12f8b;
    text-align: center;
   // border-right: 3px solid #d12f8b;
}
.custom-switch .custom-control-label::after
{
    background-color: #d12f8b;
}

.dmenu-section-items .refresh-icon {
    float: right;
    font-size: 16px;
    color: #d12f8b;
}

.dmenu-section-items .soldout
{
    font-size: 11px;
    margin-left: 5px;
    padding: 2px;
    color: #fff;   
    background-color: #ed3345;
}

.no-menus-img
{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -72px;
}

