.dmenu-section-items .menu-goup
{
    position: fixed;
    bottom: 82px;
    right: 0;
    left: 40%;  
    z-index: 10; 
    display: inline-table;
}

.dmenu-section-items .menu-goup .btn
{
    border-radius: 0px;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    
}

// Modal - Start
.dmenu-section-items .custom-modal {
    position: fixed;
    //top: 112px;
    top: 84px;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.dmenu-section-items .custom-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    //width: 183px;
    width: 200px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    outline: 0;
    //height: 190px;
    height: 247px;
    margin: 0 auto;
    overflow: auto;
}
.dmenu-section-items .custom-modal-body
{
    padding: 2px;
}

.dmenu-section-items .custom-modal-body ul
{
    padding: 2px;
    font-size: 13px;
    color: #212529;
    font-weight: 400;
    list-style: none;
    padding-left: 26px;

}

.dmenu-section-items .custom-modal-body ul li
{ 
    //line-height: 24px;
    cursor: pointer;
    text-transform: capitalize;
   
}
.dmenu-section-items .custom-modal-body ul li .list
{ 
    display: inline-block;
    width: 130px;
    // white-space: nowrap;
    // overflow: hidden !important;
    // text-overflow: ellipsis;
    padding: 6px 0 6px 0;
   
}

.dmenu-section-items .custom-modal-body ul li .clist
{ 
    display: inline-block;
    width: 130px; // 107px
    // white-space: nowrap;
    // overflow: hidden !important;
    // text-overflow: ellipsis;
    color: #d12f8b;
    padding: 6px 0 6px 0;
   
}
.dmenu-section-items .custom-modal-body ul li .count
{ 
    padding-left: 10px;
    display: inline-block;
    overflow: hidden !important;   
}

.dmenu-section-items .custom-modal-body ul li .ccount
{ 
    padding-left: 10px;
    display: inline;
    overflow: hidden !important;
    color: #d12f8b;   
}



.em-popup-menugroup-btn
{
    border: 1px solid #ffc107 !important;
    color: #f9f2ea ;
    background-color: #59324c ;
}

.em.popup-menugroup-list
{
    text-overflow: unset;
    width: 256px;
    color: #2d1926;
    font-size: 14px;
    font-weight: 600;
}

.em-popup-menugroup-list
{    
    // overflow: hidden !important;
    // text-overflow: ellipsis;
    // width: 158px;
    // white-space: nowrap;
    // color: #59324c;
    font-size: 14px;
    font-weight: 500;
    // line-height: 32px;
    // display: inline-block;
    display: inline-block;
    width: 130px;    
    padding: 6px 0 6px 0;
}

.em-popup-menugroup-clist
{    
    // width: 158px;
    // white-space: nowrap;
    // overflow: hidden !important;
    // text-overflow: ellipsis;
    // color: #28a846 !important;
    font-size: 14px;
    font-weight: 500;
    // line-height: 32px;
    // display: inline-block;
    display: inline-block;
    width: 130px; // 107px 
   color: #d12f8b !important;
   padding: 6px 0 6px 0;
}

.em-menu-goup
{
    bottom: 20px !important;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #fff;
    width: 2px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);    
}