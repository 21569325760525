.section-addressentry .modal-content {
  border-radius: 0;
  position: absolute;
  // bottom: 0px; 
  bottom: 40px;
}

.section-addressentry .modal-title {
  font-size: 13px;
  font-weight: 600;
}

.section-addressentry .modal-header {
  padding: 8px 8px;
  // background-color: #ebf9ef;

  // commented by dasprakash on 23-10-2023 jira-1677
  // border-bottom: 1px solid #d1d5d7;

  // added by dasprakash on 23-10-2023 jira-1677
  border-bottom: 0px solid #d1d5d7;

}

.section-request .modal-body {

  // commented by dasprakash on 23-10-2023 jira-1677
  // padding: 8px;
  // max-height: 370px;
  overflow-x: auto;

  // addded by dasprakash on 23-10-2023 jira-1677
  background-color: #F1F0F5;
  padding: 0px;
  max-height: 391px;
  height: 391px;
  border-radius: 20px 20px 0px 0px;

}

.section-request .food-type-img {
  margin-top: -3px;
}

.section-request .modal-body .food-type-img {
  margin-left: 6px;
}

.section-request .modal-body .modifier-group-li {
  line-height: 12px;
  list-style: none;
}

.section-request .modal-body .modifier-group-li .group-name {
  font-size: 12px;
  font-weight: 700;
}

.section-request .modal-body .modifier-group-li .minimum-selection {
  font-size: 16px;
  vertical-align: middle;
  margin-left: -12px;
  position: relative;
  margin-right: 5px;
  font-weight: 400;
}

.section-request .modal-body .modifier-li {
  line-height: 28px;
  margin-top: 4px;
  list-style: none;
  margin-left: -60px;
}

.section-request .modal-body .rate-inr {
  font-size: 11px;
  color: #585252;
}

.section-request .modal-footer {
  // -webkit-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  // -moz-box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  // box-shadow: 1px 1px 12px 1px rgba(177, 170, 170, 0.59);
  padding: 2px;
}

.section-request .modal-footer .cart {
  //position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  color: #212529;
  padding: 10px;
  font-weight: 400;
  height: 40px;
  cursor: pointer;
  z-index: 10;
  left: inherit;
  max-width: 600px;
  border-top: 1px solid #078bd1;
  border-bottom: 1px solid #078bd1;
}

.section-request .cart .c-left {
  float: left;
}

.section-request .cart .c-right {
  float: right;
}

.section-request .close {
  float: right;
  font-size: 18px;
  line-height: 1;
  color: #212529;
  text-shadow: 0 1px 0 #fff;
  /* opacity: 0.5; */
}

.section-request .refresh-icon {
  float: right;
  font-size: 13px;
  color: #000000;
}
// commented by dasprakash on 28-10-2023 jira-1677
// .section-request .react-tabs {
//   height: 310px;
// }

.section-request .react-tabs__tab {
  width: 50%;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  background-color: #FAFAFA;

}

.section-request .react-tabs__tab-list {
  border-bottom: 1px solid #d1d5d7;
  margin: 0 0 10px;
  padding: 0;
}

.section-request .react-tabs__tab--selected {
  // commented by dasprakash on 23-10-2023 jira-1677
  // border-color: #000;
  // color: black;
  // background: #fff;
  // border-radius: 5px 5px 0 0;

  // addded by dasprakash on 23-10-2023 jira-1677
  color: #FFF;
  border-radius: 0px;
  background: #137C24;
}

.section-request .react-tabs__tab-list {
  margin: 0 0 2px;
  position: sticky;
  top: 0px;
}

.section-request .help-list ul {
  list-style: none;
  padding-left: 0px;
}

// commented by dasprakash on 23-10-2023 jira-1677
// .section-request .help-list ul li {
//   // commented by dasprakash on 23-10-2023 jira-1677
//   // border-bottom: 1px solid #ddd;

//   // padding-bottom: 10px;
// }

.section-request .request {

  // border-bottom: 1px solid #ddd;

  // commented by dasprakash on 23-10-2023 jira-1677
  // padding-top: 12px;
  // padding-bottom: 12px;
  // padding-left: 26px;
  font-size: 12px;

  // addded by dasprakash on 23-10-2023 jira-1677
  margin-bottom: 15px;
  padding: 10px;


}

.section-request .request .help-heading {
  font-weight: 600;
}

.section-request .request .help-type {
  // commented by dasprakash on 23-10-2023 jira-1677
  // font-size: 13px;
  // color: #212529;

  // addded by dasprakash on 23-10-2023 jira-1677
  font-size: 14px;
  font-weight: 500;
  color: #000;



}

.section-request .request .help {
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.section-request .request .change-btn {
  border-radius: 0px;
  font-size: 11px;
  background-color: #ffffff;
  border: 1px solid #d12f8b;
  color: #d12f8b;
  float: right;
  margin-right: 6px;
  margin-top: -20px;
}

.section-request .selected-help {
  width: 20px;
  float: left;
  height: 27px;
  vertical-align: middle;
  position: relative;
  top: 10px;
  color: #d12f8b;
  padding-left: 8px;
}

.section-request .request .right-btn {
  float: right;
  border-radius: 0px;
  font-size: 14px;
  // commented by dasprakash on 23-10-2023 jira-1677
  // background-color: #ffffff;
  // color: #fd0000;
  // margin-right: 19px;
  // margin-top: -14px;
  // addded by dasprakash on 23-10-2023 jira-1677
  margin-right: -6px;
  margin-top: -21px;
}

.section-request .request .request-time {
  // commented by dasprakash on 23-10-2023 jira-1677
  // padding: 6px 0 6px 0;
  // color: #8a8c8e;
  // font-weight: 400;
  // font-size: 11px;
  // addded by dasprakash on 23-10-2023 jira-1677
  color: #6F7072;
  font-size: 14px;
  font-weight: 400;
}

.section-request .request .status-open {
  // commented by dasprakash on 23-10-2023 jira-1677
  // color: #ffffff;
  // background-color: #1171d8;
  // font-weight: 700;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
  // addded by dasprakash on 23-10-2023 jira-1677
  font-weight: 400;
  color: #07F;


}

.section-request .request .status-process {
  // commented by dasprakash on 23-10-2023 jira-1677
  // color: #ffffff;
  // font-weight: 700;
  // background-color: #fd7b0f;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
  // addded by dasprakash on 23-10-2023 jira-1677
  font-weight: 400;
  color: #07F;
}

.section-request .request .status-closed {
  // commented by dasprakash on 23-10-2023 jira-1677
  // color: #ffffff;
  // font-weight: 700;
  // background-color: #27ab45;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
  // addded by dasprakash on 23-10-2023 jira-1677
  color: #18A437;
  font-size: 12px;
  font-weight: 400;

}

.section-request .request .status-cancelled {
  // commented by dasprakash on 23-10-2023 jira-1677
  // color: #ffffff;
  // font-weight: 700;
  // background-color: #ff0000;
  width: 85px;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
  // addded by dasprakash on 23-10-2023 jira-1677
  color: #F00;
  font-size: 12px;
  font-weight: 400;

}

.section-request .no-data-img {
  text-align: center;
}

.section-request .react-tabs__tab-panel {
  // commented by dasprakash on 23-10-2023 jira-1677
  // max-height: 260px;
  overflow-x: auto;
}

// addded by dasprakash on 23-10-2023 jira-1677
.react-tabs__tab {
  border: 0px solid transparent !important;
  bottom: 0px !important;
  padding: 9px 12px !important;


}