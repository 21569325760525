.loading_img {
  margin-left: -25px !important;
  margin-top: 10px !important;
}

.svg {
  width: 10%;
  height: 10%;
  // margin-top: 20%
}

.screen_bg {
  // background: #8BC34A;
  background: #fff;
  min-height: calc(126vh - 156px) !important;
}

.screen-wel-msg {
  // position: absolute;
  // top: 50%;
  // width: 100%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50%;
}

.screen-img-center {
  position: fixed;
  top: 50%;
  // bottom: 0;
  left: 50%;
  margin-top: -50px;
  margin-left: -76px;
}

.screen-lucid-poweredby {
  //position: absolute;
  // bottom: 0px;
  text-align: center;
  width: 100%;
  font-size: 10px;
  font-weight: 800;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50%;
  color: #000;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  max-width: 600px;
}

.screen-waviy {
  position: relative;
}

.screen-waviy span {
  position: relative;
  display: inline-block;
  // font-size: 12px;
  text-transform: uppercase;
  animation: lucidflip 2s infinite;
  animation-delay: calc(.2s * var(--i));
  // color: rgb(255, 0, 0);
}

@keyframes lucidflip {

  0%,
  80% {
    transform: rotateY(360deg)
  }
}

.lucid-lineUp {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}