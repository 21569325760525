.loading_img {
  margin-left: -25px !important;
    margin-top: 10px !important;
}
.svg{
  width: 10%;
  height: 10%;
  // margin-top: 20%
}

.qrcode_bg{
  background: #fff;
  min-height: calc(130vh - 156px) !important;
}
.qrcode-txt-center
{
  text-align: center;     
    margin-top: 130px;
   
}

.qrcode-img-center
{
  text-align: center;
    position: fixed;
    top: 56%;
    left: 50%;
    margin-top: -50px;
    margin-left: -80px;
}


.qrcode-oops-txt
{
  font-size: 16px;
    font-weight: 700;
}
.qrcode--txt-not-found
{
  font-size: 16px;
    font-weight: 700;
    color: #82878c;
}


.qrcode-msg-txt
{
  font-size: 12px;
    font-weight: 700;
    color: #82878c;
   
}


.orentation-img
{  
    text-align: center;
    position: fixed;
    top: 56%;
    left: 50%;
    margin-top: -26px;
    margin-left: -80px;
    width: 150px;
}
.retyr-btn
{
  border: 1px solid #d12f8b;
  background-color: #fff;
  color: #212529;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  position: fixed;
  top: 56%;
  left: 50%;
  margin-top: 130px;
  margin-left: -80px;
  width: 150px;
  padding: 9px 22px;
}