.header-part{
    color: rgb(69, 69, 69);
    font-size: 14px;
    font-weight: 400;
}

.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.top-2 {
    top: 0.5rem;
}
.right-2 {
    right: 0.5rem;
}
.rounded-full {
    border-radius: 9999px;
}

.cancel-filters{
    background-color: #dbd4d4;
    border: 1px solid gray;
}
.non-selected-filters{
    
   // margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-button
{
    display: flex;
    justify-self: center;
    padding: 5px;
}
.filteritems{
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(29, 135, 235);
    color: white;
    font-size: 15px;
    border: none;

}
.filters-itemlist{
    display: flex;
    gap :20px;
    margin-bottom: 15px;
}

.categories-container {
    margin-top: 0.5rem;
    padding: 0 1rem;
}

.filter-categories {
    display: flex;
    flex-direction: column;
}


.filter-item {
    position: relative;
    flex: 2;
    padding: 0.75rem 0.75rem;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: rgba(2, 6, 12, 0.75);
    
    &.selected-filters {
        background-color: rgb(240, 240, 245);
        color: #1a1a1a;
        font-weight: 700;
        
    }
    
}

.cancel-button {
    position: absolute;
    border: none;
    background: none;
    //top: 0.5rem;
    right: 0.25rem;
    padding: 0.25rem;
    border-radius: 9999px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 700;
    
    &:hover {
        background-color: darken(#dbd4d4, 10%);
    }
}

.filter-item-placeholder {
    flex: 2;
}

.filter-button {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.filteritems {
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(29, 135, 235);
    color: white;
    font-size: 15px;
    border: none;
    cursor: pointer;
    
    &:hover {
        background-color: darken(rgb(29, 135, 235), 5%);
    }
}

.filter-items {
   // position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid rgb(194, 192, 192);
  }
  
  .apply-filters {
    width: 100%;
    padding: 15px 0;
    background-color: #18A437;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  button:focus{
    outline: none;
  }

.clear-filters{
margin-left: 55%;
}

@media screen and (min-width: 720px) {
    .clear-filters {
        margin-left: 75%;
    }
}

.clear-button{
   color: #FF0000;
   background: #fff;
   margin-top: -10px;
  border-radius: 5px;
   border: none;
}