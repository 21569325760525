.section-payment .heading-page {
    z-index: 20;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d1d5d7;
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color: #212529;
}

// .section-payment .m-t-contact-form
// {
//     margin-top: 60px;
// }


.section-payment .payment-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-payment .gateway-details {
    //commented by dasprakash on 15-11-2023 jira-1802
    // padding-left: 6px;
    // background-color: #fff;

    //added by dasprakash on 15-11-2023 jira-1802
    padding-top: 1px;

    position: relative;
    width: 100%;
    margin-top: 12px;
    -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-payment .gateway-details .heading {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 3px;
}

.section-payment .gateway-details .content {
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    height: 55px;
    padding: 14px;
    cursor: pointer;


    //added by dasprakash on 15-11-2023 jira-1802
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 11px;
    background: #FFF;
    margin: 15px;

}

.section-payment .gateway-details .content img {
    height: 31px;

}

.section-payment .gateway-details .content .payment-name {
    padding-left: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
}



.section-verification .heading-page {
    z-index: 10;
    position: fixed !important;
    top: 0px;
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 8px 15px 14px 10px;
    max-width: 600px;
    left: inherit;
}

.section-verification .m-t-contact-form {
    margin-top: 100px;
}


.section-verification .payment-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    padding: 0px;
    display: flex;
}


.section-verification .message-details {
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    height: 250px;
    //Commended by vijay on 07-11-2023
    // -webkit-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // -moz-box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
    // box-shadow: 1px 1px 12px 1px rgba(50, 50, 50, 0.08);
}

.section-verification .message-details .content {
    font-size: 12px;
    // border-bottom: 1px solid #ddd;   
    // padding: 80px;
    cursor: pointer;
}

.section-verification .message-details .msg {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 104px; // Added by vijay on 07-11-2023
    color: #18A437;
}

.section-verification .message-details .msg-order-id {
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 500;
}

.section-verification .message-details .btn {
    padding: 10px 76px;
    display: block;
    margin: 0 auto;
    background-color: var(--pink);
    margin-top: 80px;
}

.section-verification .message-details .btn-orders {
    padding: 15px;
    text-align: center;
    color: #868585;
    font-size: 13px;
    font-weight: 500;
}

.prepaid-card-balace-pay {
    float: right;
    border: 1px solid #d12f8b;
    padding: 2px 8px 4px 8px;
    font-size: 12px;
    font-weight: 500;
}

.prepaid-card-list {
    text-align: center;

    //commented by dasprakash on 16-11-2023 jira-1802 
    // padding: 10px;
    // background: #fff;
    // margin: 12px;
    // box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);

    //added by dasprakash on 16-11-2023 jira-1802 
    border-radius: 21px 0px 0px 0px;
    background: #F1F0F5;
    width: 100%;
    max-width: 600px;

    color: #000;
    display: inline-block;
}

.prepaid-card-list ul {
    list-style: none;
    //added by dasprakash on 16-11-2023 jira-1802 
    // padding-left: 0px;
    text-align: left;
    padding: 15px;
    //added by dasprakash on 16-11-2023 jira-1802 
    margin-bottom: 0px;
}

//commented by dasprakash on 16-11-2023 jira-1802 
// .prepaid-card-list ul li {
//     border-bottom: 1px solid #ddd;
//     // padding-bottom: 10px;
// }

.prepaid-card-list>button {
    width: 90px;
    padding: 10px;
    border: 1px solid #d12f8b;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #000;
    font-size: 12px;
    background-color: #ffffff;
    box-shadow: 1px 2px 3px 0px #d1d5d7;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: 1px solid #d1d5d7 !important;
    color: #000;
    font-weight: 600;
}

.prepaid-card-list .prepaid-card-account {
    //commented by dasprakash on 16-11-2023 jira-1802 
    // padding-top: 12px;
    // padding-bottom: 12px;
    // padding-left: 11px;
    font-size: 14px;

    border-radius: 11px;
    background: #FFF;
    margin-bottom: 15px;
    //added by dasprakash on 16-11-2023 jira-1802 
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.prepaid-card-list .prepaid-card-account .pay-btn {

    float: right;
    border: 1px solid #d12f8b;
    padding: 2px 28px 4px 28px;
    font-size: 12px;
    font-weight: 500;
    margin-top: -2px;
    margin-left: 10px;
}

.prepaid-card-list .prepaid-card-account .pay-wallet-bal {
    display: block;
    //commented by dasprakash on 16-11-2023 jira-1802 
    // color: #078bd1;
    // font-size: 12px;

    //added by dasprakash on 16-11-2023 jira-1802 
    font-size: 14px;
    font-weight: 500;
    color: #000;

}

.prepaid-card-list .prepaid-card-account .non-pay-btn {
    display: block;
    //commented by dasprakash on 16-11-2023 jira-1802 
    // font-size: 12px;
    // color: #fd7e14;
    //added by dasprakash on 16-11-2023 jira-1802 
    color: rgba(255, 0, 0, 0.71);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}

.prepaid-card-list .itm-total-txt {
    font-size: 9px;
    text-align: center !important;
    color: #8e9294;
    /* border-bottom: 1px solid; */
    border-bottom: 1px solid #ddd;
    //commented by dasprakash on 16-11-2023 jira-1802
    // padding: 4px;
    //added by dasprakash on 16-11-2023 jira-1802
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;


}

.logo-v2-process-request {
    //Added by vijay on 07-11-2023 Jira id -1687 -start
    align-items: center;
    background: #CBE9DA;
    border-bottom-left-radius: 60%;
    border-bottom-right-radius: 60%;
    display: flex;
    height: 170px;
    justify-content: center;
    margin-top: -52px;

}

@keyframes running-dots {

    //Added by vijay on 07-11-2023 Jira id -1687 -start
    0%,
    100% {
        transform: translateX(0) scale(1);
    }

    50% {
        transform: translateX(5px) scale(1.4);
    }
}

.running-dots {
    //Added by vijay on 07-11-2023 Jira id -1687 -start
    display: inline-block;
    // animation: status-message 1s infinite, running-dots 2s infinite;

    animation: running-dots 1s infinite;
    /* 1s is the duration, infinite means it will run indefinitely */
}

.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.25);
    align-items: flex-end;
}

.react-confirm-alert {
    width: 100%;

}

.payondelivery-v2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.payondelivery-buttons-v2 {
    width: 100%;
    height: 61px;
    display: flex;
    font-size: 20px;
    font-weight: 500;

    &-1 {
        color: #FFF;
        width: 50%;
        background: #18A437;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-2 {
        width: 50%;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}