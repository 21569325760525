.section-address .heading-page {
  z-index: 10;
  position: fixed !important;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #d1d5d7;
  max-width: 600px;
  left: inherit;
  font-size: 13px;
  color: #212529;
  text-align: center; // Added by vijay on 09-11-2023
}

.section-address .address-list ul {
  list-style: none;
  padding-left: 0px;
}

.section-address .address-list ul li {
  // border-bottom: 1px solid #ddd; //Commended by vijay on 09-11-2023
  // padding-bottom: 10px;
  
  padding: 12px; //Added by vijay on 09-11-2023
  margin: 13px;
  border-radius: 11px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  background: #FFF;
}

.section-address .delivery {

  //padding-top: 10px;
  // border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-left: 20px;  // Modified by vijay on 16-11-2023
  font-size: 12px;
}

.section-address .delivery .addr-heading {
  font-weight: 600;
}

.section-address .delivery .addr-type {
  font-size: 13px;
  color: #212529;
}

.section-address .delivery .address {
  font-size: 13px;  // Added by vijay on 16-11-2023
  margin-top: 8px;
}

.section-address .delivery .change-btn {
  // border-radius: 0px;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #d12f8b;
  color: #d12f8b;
  float: right;
  // margin-right: 6px;   Commended by vijay on 10-11-2023
  margin-top: -20px;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #18A437 !important;
  color: #000;
  font-weight: 600;
  background-color: #DCF1E1;
}

.section-address .selected-address {
  width: 20px;
  float: left;
  height: 27px;
  vertical-align: middle;
  position: relative;
  //top: 10px;
  color: #18A437;
  // padding-left: 8px; commended by vijay on 16-11-2023
}

.section-address .add-address {
  font-size: 14px;   // Modified by vijay on 16-11-2023
  padding-left: 16px;
  margin-bottom: 10px;
  margin-top: 60px;
  font-weight: 600;
}

.add-address{
  color: #18A437; //Added by vijay on 09-11-2023
}
.section-address .add-address .plus {
  color: #18A437;   //Modified by vijay on 09-11-2023
}

.section-address .delivery-heading {
  // background-color: #ececec;
  // padding: 6px;
  // color: #212529;
   padding-left: 16px;
  color: #7F7F7F;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.section-address .address-entry {
  padding: 14px;
  margin-bottom: 38px;
  padding-top: 4px;
  // box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  padding-bottom: 93px;  // Added by vijay on 16-11-2023
}


.section-address .address-entry .form-group {
  margin-bottom: 2px;
}

.section-address .address-entry .form-control {
  font-size: 10px;
 // border-color: #d1d5d7;
  border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.21);
background: #FFF;
}

.section-address .save-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  color: #212529;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  cursor: pointer;
  max-width: 600px;
  left: inherit;
  border-top: 1px solid #078bd1;
  border-bottom: 1px solid #078bd1;
  background-color: #289C3A;
  ///box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #d1d5d7 !important;
  color: #FFFFFF; // Added by vijay on 09-11-2023
  font-weight: 600;
  border-radius: 11px; // Added by vijay on 09-11-2023
  margin-top: -76px;  //Added by vijay on 16-11-2023
}

.section-address .address-entry .form-control:focus {
  border-color: #d1d5d7;
  outline: 0;
}

.section-address .address-entry .address-type-btn {
  // border-radius: 0px;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid #d1d5d7;
  color: #000;
  margin-right: 6px;
}

.section-address .address-entry .selected-btn {
  background-color: #DCF1E1;
  color: #000;
  font-weight: 600;
  border: 1px solid #18A437 !important;  //Added by vijay on 09-11-2023
}

.section-address .delivery .right-btn {
  border-radius: 0px;
  font-size: 14px;
  background-color: #ffffff;
  color: #18A437;
  float: right;
  margin-right: 19px;
  margin-top: -14px;
}

.section-address .address-entry li {
  display: contents;
}

.section-address .delivery-address {
  background-color: #d1d5d7;
  padding: 6px;
  color: #000;
  padding-left: 26px;
  margin-top: -2px;
}
.right-btn-address{   // Added by vijay on 10-11-2023
float: right;
margin-top: -14px;
}