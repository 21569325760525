.section-helprequest .heading-page
{
    z-index: 10;
    position: fixed !important;
    top: 0px;  
    left: 0px;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #078bd1;  
    max-width: 600px;
    left: inherit;
    font-size: 13px;
    color:  #212529;
}
.section-helprequest .refresh-icon
{
    float: right;
    font-size: 13px;
    color: #d12f8b;
}
.section-helprequest .react-tabs__tab
{   
        width: 50%;
        text-align: center;
}

.section-helprequest .react-tabs__tab-list {
    border-bottom: 1px solid #078bd1;
    margin: 0 0 10px;
    padding: 0;
}
.section-helprequest .react-tabs__tab--selected {
    background: #fff;
    border-color: #d12f8b;
    color: black;
    border-radius: 5px 5px 0 0;
}
.section-helprequest .react-tabs__tab-list
{
    margin: 0 0 2px;    
}

.section-helprequest .help-list ul
{
  list-style: none;
  padding-left: 0px;
}
.section-helprequest .help-list ul li
{
  border-bottom: 1px solid #ddd;
 // padding-bottom: 10px;
}
.section-helprequest .request
  {
    
    padding-top: 12px;
    // border-bottom: 1px solid #ddd;
    padding-bottom: 12px;    
    padding-left: 26px;
    font-size: 12px;   
  }
  .section-helprequest .request .help-heading
  {    
    font-weight: 600;        
  }
  .section-helprequest .request .help-type
  {
    font-size: 13px;    
    color: #212529;
  }
  .section-helprequest .request .help
  {
    font-size: 11px;   
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .section-helprequest .request .change-btn
  {
    border-radius: 0px;
    font-size: 11px;
    background-color: #ffffff;
    border: 1px solid #d12f8b;
    color: #d12f8b;
    float: right;
    margin-right: 6px;
    margin-top: -20px;
  }
  .section-helprequest .selected-help
  {
    width: 20px;
    float: left;
    height: 27px;
    vertical-align: middle;
    position: relative;
    top: 10px;
    color: #d12f8b;
    padding-left: 8px;
  }
  .section-helprequest .request .right-btn
  {
    border-radius: 0px;
    font-size: 14px;
    background-color: #ffffff;
    color: #d12f8b;
    float: right;
    margin-right: 19px;
    margin-top: -14px;
  }

  .section-helprequest .request .request-time{
    padding: 6px 0 6px 0;
    color: #8a8c8e;
    font-weight: 400;
    font-size: 11px;
  }

  .section-helprequest .request .status-open
  {
    color: #ffffff;
    font-weight: 700;
    background-color: #1171d8;
    width: 85px;
    border-radius: 2px;
    padding: 2px;
    text-align: center;
  }
  .section-helprequest .request .status-process
  { 
    color: #ffffff;
    font-weight: 700;
    background-color: #fd7b0f;
    width: 85px;
    border-radius: 2px;
    padding: 2px;
    text-align: center;
  }
  .section-helprequest .request .status-closed
  {    
    color: #ffffff;
    font-weight: 700;
    background-color: #27ab45;
    width: 85px;
    border-radius: 2px;
    padding: 2px;
    text-align: center;
  }
  .section-helprequest .request .status-cancelled
  {   
    color: #ffffff;
    font-weight: 700;
    background-color: #ff0000;
    width: 85px;
    border-radius: 2px;
    padding: 2px;
    text-align: center;   
  }