#login-content {
  width: 100%;
  min-height: calc(100vh - 56px);
  transition: all 0.3s;
  top: 0px;
  right: 0;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}


#login-content {
  background-color: #f4f4f4;
  /*---------- Homepage Slider ----------*/
}

#login-content .img-hero {
  height: 360px;
  margin-bottom: -15px;
  overflow: hidden;
}

#login-content .img-hero .slick-slide img {
  width: 100%;
  height: auto;
}

#login-content .img-hero ul.slick-dots {
  bottom: 20px;
}

#login-content .img-hero ul.slick-dots li {
  margin: 0px;
}

#login-content .img-hero ul.slick-dots li button {
  background-color: #ffffff;
}

#login-content .img-hero ul.slick-dots li button:before {
  color: #fff;
  font-size: 0px;
}


.img-hero img {
  // width: 100%;
  // height: auto;
  width: 100%;
  height: 240px;
}

.img-center {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}


.login-section-home {
  overflow: hidden;
  background-color: #ffffff;
  width: 100%;
  //margin: -176px auto 26px;

  // commented by dasprakash on 16-10-2023 jira-1574
  // margin: -159px auto 26px;

  // -webkit-border-radius: 10px;
  // -moz-border-radius: 10px;

  // commented by dasprakash on 16-10-2023 jira-1574
  // border-radius: 10px;
  // padding: 20px 15px;

  // added by dasprakash on 16-10-2023 jira-1574
  border-top-left-radius: 21px;

  padding: 20px 0px;
  // -webkit-box-shadow: 4px 4px 7px 1px rgba(50, 50, 50, 0.23);
  // -moz-box-shadow: 4px 4px 7px 1px rgba(50, 50, 50, 0.23);
  // box-shadow: 4px 4px 7px 1px rgba(50, 50, 50, 0.23);
}

// added by dasprakash on 16-10-2023 jira-1574

.login-section-v2 {
  border-radius: 35px;
  background: #CEEADC;
  box-shadow: 1px 1px 5px 0px rgba(206, 234, 220, 0.25);
  padding: 30px;
  margin: 50px auto;
  width: 95% !important;
}

.modal-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1050;
  /* Adjust the z-index as needed */
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.login-section-home .sa-title {
  // commented by dasprakash on 16-10-2023 jira-1574
  // color: #212529;
  // font-size: 18px;
  // margin-bottom: 5px;
  // font-weight: 600;


  // added by dasprakash on 16-10-2023 jira-1574
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-section-home .heading-section {
  position: relative;
  margin-bottom: 25px;
  margin-top: 0px;
}

.login-section-home .heading-section .sa-title.popcat {
  width: 100%;
  line-height: 1;
  text-align: center;
}

.login-section-home .more-category {
  width: 100%;
  text-align: center;
  padding: 10px 0px 0px;
}

.login-section-home .more-category .mcbutton {
  position: relative;
  right: 0px;
  bottom: 0px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}

.login-ection-a {
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 15px 15px;
}

.login-section-home.s-category {
  position: relative;
}

.login-panel {
  position: relative;
}

.login-text {
  margin: 5px auto 0px;
  line-height: 1.2;
  // font-size: 12px;
  padding: 0px 5px;
  text-align: center;
  margin-bottom: 12px;

  // added by dasprakash on 16-10-2023 jira-1574

  color: rgba(34, 34, 34, 0.81);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  // line-height: normal;
}

.login-input {
  max-width: 80%;
  min-width: 66%;
  display: block;
  margin: auto;
  // text-align: center;
  //border: 1px solid #078bd1;
  // border: 1px solid #d1d5d7 !important;
}

.login-section-home .heading-section .submit-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.login-send-btn {
  padding: 10px 66px;
  border: 1px solid #d12f8b;
  background-color: #fff;
  color: #212529;
  font-size: 13px;
  font-weight: 400;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #d1d5d7 !important;
  color: #000;
  font-weight: 600;
}

.login-send-otp-btn {
  padding: 10px 76px;
  border: 1px solid #d12f8b;
  ;
  background-color: #fff;
  color: #212529;
  font-size: 13px;
  font-weight: 400;
  min-width: 66%;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 0px #d1d5d7;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid #d1d5d7 !important;
  color: #000;
  font-weight: 600;
}


.table-verify-btn {
  padding: 10px 76px;
  border: 1px solid #d12f8b;
  ;
  background-color: #fff;
  color: #212529;
  font-size: 13px;
  font-weight: 400;
  min-width: 66%;
}

body .login-section-home .form-control:focus {
  border-color: #d12f8b;
  outline: 0;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 0 0 8px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0), 0 0 8px rgba(0, 0, 0, 0);
}

.login-section-home .theme-button:hover {
  background-color: #fff;
}

.login-section-home button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}

.login-section-home .form-control {
  // commented by dasprakash on 16-10-2023 jiora-1574
  // font-size: 10px;
  // commented by dasprakash on 16-10-2023 jiora-1574
  font-size: 13px;
  font-weight: 400;
}

.input_v2::placeholder {
  color: #C1B9B9;
  font-size: 13px;
  font-weight: 400;
}

.login-section-home .table-img {
  width: 46px;
}

.login-resend-otp {
  text-align: center;
  margin: 0 auto;
}

.login-resend-otp .resend-otp-text {
  border-bottom: 1px solid #d12f8b;
  font-weight: 600;
  cursor: pointer;
  color: #212529;
  font-size: 12px;
  margin: 0 auto;
}

// group piN COMMENTED BY DASPRAKASH ON 09-10-2023 JIRA-1490
// .login-resend-otp .back {
//   margin-left: 24px;
// }

.login-selection-resend {
  text-align: center;
  margin: 0 auto;
}

.show-message {
  max-width: 350px;
  overflow: hidden;
  font-size: 12px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d5d7;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
  margin-top: 10px;
  margin-right: 10px;
}

.popup-content {
  background-color: #292d3e;
  color: #fff;
  border: 1px solid #464545;
  animation: anvil .3s cubic-bezier(.38, .1, .36, .9) forwards;
  -webkit-animation: anvil .3s cubic-bezier(.38, .1, .36, .9) forwards;
  border-radius: 5px;
  padding: .5rem;
  position: relative;
  margin: auto;
  max-width: 600px;
  width: 90%;
  pointer-events: auto;
}

.popup-content .button {
  font-family: Roboto, Arial, sans-serif;
  color: #000;
  cursor: pointer;
  padding: 0 30px;
  display: inline-block;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 1.5px;
  font-size: 1em;
  outline: 0;
  position: relative;
  font-size: 14px;
  border: 3px dashed var(--ifm-link-color);
  background-color: #fff;
  transition: all .3s;
}


input[type="text"]::placeholder {

  /* Firefox, Chrome, Opera */
  text-align: center;
}

input[type="text"]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  text-align: center;
}

input[type="text"]::-ms-input-placeholder {

  /* Microsoft Edge */
  text-align: center;
}

.logo-v2 {
  align-items: center;
  background: #CBE9DA;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;
  display: flex;
  height: 170px;
  justify-content: center;
  width: 100%;
}

.input_v2 {
  margin: 0 auto;
  width: 90%;
  height: 41px;
  background-color: red;
  border-radius: 11px;
  background: #FFF;
  display: flex;
  align-items: center;
}